var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-spin",
    { attrs: { spinning: _vm.loading } },
    [
      _c(
        "a-row",
        { attrs: { type: "flex" } },
        [
          _c(
            "a-col",
            [_vm._t("buttonBefore", null, { target: _vm.getVM() })],
            2
          ),
          _c("a-col", [
            _vm.actionButton
              ? _c(
                  "div",
                  { staticClass: "action-button" },
                  [
                    _vm.buttonPermission("add")
                      ? _c(
                          "a-button",
                          {
                            attrs: {
                              type: "primary",
                              icon: "plus",
                              disabled: _vm.disabled,
                            },
                            on: { click: _vm.handleClickAdd },
                          },
                          [_vm._v("新增")]
                        )
                      : _vm._e(),
                    _c("span", { staticClass: "gap" }),
                    _vm.selectedRowIds.length > 0
                      ? [
                          _c(
                            "a-popconfirm",
                            {
                              attrs: {
                                title: `确定要删除这 ${_vm.selectedRowIds.length} 项吗?`,
                              },
                              on: { confirm: _vm.handleConfirmDelete },
                            },
                            [
                              _vm.buttonPermission("batch_delete")
                                ? _c(
                                    "a-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        icon: "minus",
                                        disabled: _vm.disabled,
                                      },
                                    },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e(),
                              _c("span", { staticClass: "gap" }),
                            ],
                            1
                          ),
                          _vm.showClearSelectButton
                            ? [
                                _c(
                                  "a-button",
                                  {
                                    attrs: { icon: "delete" },
                                    on: {
                                      click: _vm.handleClickClearSelection,
                                    },
                                  },
                                  [_vm._v("清空选择")]
                                ),
                                _c("span", { staticClass: "gap" }),
                              ]
                            : _vm._e(),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
          ]),
          _c(
            "a-col",
            [_vm._t("buttonAfter", null, { target: _vm.getVM() })],
            2
          ),
        ],
        1
      ),
      _vm._t("actionButtonAfter", null, { target: _vm.getVM() }),
      _c(
        "div",
        {
          staticClass: "input-table",
          attrs: { id: `${_vm.caseId}inputTable` },
        },
        [
          _c("div", { ref: "thead", staticClass: "thead" }, [
            _c(
              "div",
              { staticClass: "tr", style: { width: this.realTrWidth } },
              [
                _vm.dragSort
                  ? _c(
                      "div",
                      { staticClass: "td td-ds", style: _vm.style.tdLeft },
                      [_c("span")]
                    )
                  : _vm._e(),
                _vm.rowSelection
                  ? _c(
                      "div",
                      { staticClass: "td td-cb", style: _vm.style.tdLeft },
                      [
                        _c("a-checkbox", {
                          attrs: {
                            checked: _vm.getSelectAll,
                            indeterminate: _vm.getSelectIndeterminate,
                          },
                          on: { change: _vm.handleChangeCheckedAll },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.rowNumber
                  ? _c(
                      "div",
                      { staticClass: "td td-num", style: _vm.style.tdLeft },
                      [_c("span", [_vm._v("#")])]
                    )
                  : _vm._e(),
                _vm._l(_vm.columns, function (col) {
                  return [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: col.type !== _vm.formTypes.hidden,
                            expression: "col.type !== formTypes.hidden",
                          },
                        ],
                        key: col.key,
                        staticClass: "td",
                        style: _vm.buildTdStyle(col),
                      },
                      [_c("span", [_vm._v(_vm._s(col.title))])]
                    ),
                  ]
                }),
              ],
              2
            ),
          ]),
          _c(
            "div",
            {
              ref: "scrollView",
              staticClass: "scroll-view",
              style: { "max-height": _vm.maxHeight + "px" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "tbody",
                  style: _vm.tbodyStyle,
                  attrs: { id: `${_vm.caseId}tbody` },
                },
                [
                  _c("div", {
                    staticClass: "tr-expand",
                    style: `height:${_vm.getExpandHeight}px; z-index:${
                      _vm.loading ? "11" : "9"
                    };`,
                  }),
                  _vm.rows.length === 0
                    ? _c("div", { staticClass: "tr-nodata" }, [
                        _c("span", [_vm._v("暂无数据")]),
                      ])
                    : _vm._e(),
                  _c(
                    "draggable",
                    {
                      attrs: { value: _vm.rows, handle: ".td-ds-icons" },
                      on: {
                        start: _vm.handleDragMoveStart,
                        end: _vm.handleDragMoveEnd,
                      },
                    },
                    [
                      _vm._l(_vm.rows, function (row, rowIndex) {
                        return [
                          rowIndex >=
                            parseInt(
                              `${
                                (_vm.scrollTop - _vm.rowHeight) / _vm.rowHeight
                              }`
                            ) &&
                          parseInt(`${_vm.scrollTop / _vm.rowHeight}`) + 9 >
                            rowIndex
                            ? _c(
                                "div",
                                {
                                  key: row.id,
                                  staticClass: "tr",
                                  class:
                                    _vm.selectedRowIds.indexOf(row.id) !== -1
                                      ? "tr-checked"
                                      : "",
                                  style: _vm.buildTrStyle(rowIndex),
                                  attrs: {
                                    id: `${_vm.caseId}tbody-tr-${rowIndex}`,
                                    "data-idx": rowIndex,
                                  },
                                  on: { click: _vm.handleClickTableRow },
                                },
                                [
                                  _vm.dragSort
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "td td-ds",
                                          style: _vm.style.tdLeft,
                                          on: {
                                            dblclick: function ($event) {
                                              return _vm._handleRowInsertDown(
                                                rowIndex
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "a-dropdown",
                                            {
                                              attrs: {
                                                trigger: ["click"],
                                                getPopupContainer:
                                                  _vm.getParentContainer,
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "td-ds-icons" },
                                                [
                                                  _c("a-icon", {
                                                    attrs: {
                                                      type: "align-left",
                                                    },
                                                  }),
                                                  _c("a-icon", {
                                                    attrs: {
                                                      type: "align-right",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "a-menu",
                                                {
                                                  attrs: { slot: "overlay" },
                                                  slot: "overlay",
                                                },
                                                [
                                                  _c(
                                                    "a-menu-item",
                                                    {
                                                      key: "0",
                                                      attrs: {
                                                        disabled:
                                                          rowIndex === 0,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm._handleRowMoveUp(
                                                            rowIndex
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("向上移")]
                                                  ),
                                                  _c(
                                                    "a-menu-item",
                                                    {
                                                      key: "1",
                                                      attrs: {
                                                        disabled:
                                                          rowIndex ===
                                                          _vm.rows.length - 1,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm._handleRowMoveDown(
                                                            rowIndex
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("向下移")]
                                                  ),
                                                  _c("a-menu-divider"),
                                                  _c(
                                                    "a-menu-item",
                                                    {
                                                      key: "3",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm._handleRowInsertDown(
                                                            rowIndex
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("插入一行")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.rowSelection
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "td td-cb",
                                          style: _vm.style.tdLeft,
                                        },
                                        [
                                          _vm._l(
                                            [`${row.id}`],
                                            function (id, i) {
                                              return [
                                                _c("a-checkbox", {
                                                  key: i,
                                                  attrs: {
                                                    id: id,
                                                    checked:
                                                      _vm.selectedRowIds.indexOf(
                                                        id
                                                      ) !== -1,
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.handleChangeLeftCheckbox,
                                                  },
                                                }),
                                              ]
                                            }
                                          ),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  _vm.rowNumber
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "td td-num",
                                          style: _vm.style.tdLeft,
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(rowIndex + 1)),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._l(_vm.columns, function (col) {
                                    return _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              col.type !== _vm.formTypes.hidden,
                                            expression:
                                              "col.type !== formTypes.hidden",
                                          },
                                        ],
                                        key: col.key,
                                        staticClass: "td",
                                        style: _vm.buildTdStyle(col),
                                      },
                                      [
                                        _vm._l(
                                          [`${col.key}${row.id}`],
                                          function (id, i) {
                                            return [
                                              col.type ===
                                                _vm.formTypes.input ||
                                              col.type ===
                                                _vm.formTypes.inputNumber
                                                ? _c(
                                                    "label",
                                                    { key: i },
                                                    [
                                                      _c(
                                                        "a-tooltip",
                                                        _vm._b(
                                                          {},
                                                          "a-tooltip",
                                                          _vm.buildTooltipProps(
                                                            row,
                                                            col,
                                                            id
                                                          ),
                                                          false
                                                        ),
                                                        [
                                                          _vm.isEditRow(
                                                            row,
                                                            col
                                                          )
                                                            ? _c(
                                                                "input",
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      id: id,
                                                                      "data-input-number":
                                                                        col.type ===
                                                                        _vm
                                                                          .formTypes
                                                                          .inputNumber,
                                                                      placeholder:
                                                                        _vm.replaceProps(
                                                                          col,
                                                                          col.placeholder
                                                                        ),
                                                                    },
                                                                    on: {
                                                                      blur: (
                                                                        e
                                                                      ) => {
                                                                        _vm.handleBlurCommono(
                                                                          e.target,
                                                                          rowIndex,
                                                                          row,
                                                                          col
                                                                        )
                                                                      },
                                                                      input: (
                                                                        e
                                                                      ) => {
                                                                        _vm.handleInputCommono(
                                                                          e.target,
                                                                          rowIndex,
                                                                          row,
                                                                          col
                                                                        )
                                                                      },
                                                                    },
                                                                  },
                                                                  "input",
                                                                  _vm.buildProps(
                                                                    row,
                                                                    col
                                                                  ),
                                                                  false
                                                                )
                                                              )
                                                            : _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "j-td-span no-edit",
                                                                  class: {
                                                                    disabled:
                                                                      _vm.buildProps(
                                                                        row,
                                                                        col
                                                                      )
                                                                        .disabled,
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleEditRow(
                                                                          row,
                                                                          col
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm
                                                                        .inputValues[
                                                                        rowIndex
                                                                      ][col.key]
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : col.type ===
                                                  _vm.formTypes.checkbox
                                                ? [
                                                    _c(
                                                      "a-checkbox",
                                                      _vm._b(
                                                        {
                                                          key: i,
                                                          attrs: {
                                                            id: id,
                                                            checked:
                                                              _vm
                                                                .checkboxValues[
                                                                id
                                                              ],
                                                          },
                                                          on: {
                                                            change: (e) =>
                                                              _vm.handleChangeCheckboxCommon(
                                                                e,
                                                                row,
                                                                col
                                                              ),
                                                          },
                                                        },
                                                        "a-checkbox",
                                                        _vm.buildProps(
                                                          row,
                                                          col
                                                        ),
                                                        false
                                                      )
                                                    ),
                                                  ]
                                                : col.type ===
                                                  _vm.formTypes.select
                                                ? [
                                                    _c(
                                                      "a-tooltip",
                                                      _vm._b(
                                                        {},
                                                        "a-tooltip",
                                                        _vm.buildTooltipProps(
                                                          row,
                                                          col,
                                                          id
                                                        ),
                                                        false
                                                      ),
                                                      [
                                                        _vm.isEditRow(row, col)
                                                          ? _c(
                                                              "a-select",
                                                              _vm._b(
                                                                {
                                                                  key: i,
                                                                  staticStyle: {
                                                                    width:
                                                                      "100%",
                                                                  },
                                                                  attrs: {
                                                                    id: id,
                                                                    value:
                                                                      _vm
                                                                        .selectValues[
                                                                        id
                                                                      ],
                                                                    options:
                                                                      col.options,
                                                                    getPopupContainer:
                                                                      _vm.getParentContainer,
                                                                    placeholder:
                                                                      _vm.replaceProps(
                                                                        col,
                                                                        col.placeholder
                                                                      ),
                                                                    filterOption:
                                                                      (i, o) =>
                                                                        _vm.handleSelectFilterOption(
                                                                          i,
                                                                          o,
                                                                          col
                                                                        ),
                                                                    maxTagCount: 1,
                                                                    allowClear:
                                                                      "",
                                                                  },
                                                                  on: {
                                                                    change: (
                                                                      v
                                                                    ) =>
                                                                      _vm.handleChangeSelectCommon(
                                                                        v,
                                                                        id,
                                                                        row,
                                                                        col
                                                                      ),
                                                                    search: (
                                                                      v
                                                                    ) =>
                                                                      _vm.handleSearchSelect(
                                                                        v,
                                                                        id,
                                                                        row,
                                                                        col
                                                                      ),
                                                                    blur: (v) =>
                                                                      _vm.handleBlurSearch(
                                                                        v,
                                                                        id,
                                                                        row,
                                                                        col
                                                                      ),
                                                                  },
                                                                },
                                                                "a-select",
                                                                _vm.buildProps(
                                                                  row,
                                                                  col
                                                                ),
                                                                false
                                                              )
                                                            )
                                                          : _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "j-td-span no-edit",
                                                                class: {
                                                                  disabled:
                                                                    _vm.buildProps(
                                                                      row,
                                                                      col
                                                                    ).disabled,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.stopPropagation()
                                                                      return _vm.handleEditRow(
                                                                        row,
                                                                        col
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getSelectTranslateText(
                                                                      _vm
                                                                        .selectValues[
                                                                        id
                                                                      ],
                                                                      row,
                                                                      col
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : col.type ===
                                                  _vm.formTypes.sel_depart
                                                ? [
                                                    _c(
                                                      "a-tooltip",
                                                      _vm._b(
                                                        {},
                                                        "a-tooltip",
                                                        _vm.buildTooltipProps(
                                                          row,
                                                          col,
                                                          id
                                                        ),
                                                        false
                                                      ),
                                                      [
                                                        _vm.isEditRow(row, col)
                                                          ? _c(
                                                              "j-select-depart",
                                                              _vm._b(
                                                                {
                                                                  key: i,
                                                                  staticStyle: {
                                                                    width:
                                                                      "100%",
                                                                  },
                                                                  attrs: {
                                                                    id: id,
                                                                    value:
                                                                      _vm
                                                                        .departCompValues[
                                                                        id
                                                                      ],
                                                                    placeholder:
                                                                      _vm.replaceProps(
                                                                        col,
                                                                        col.placeholder
                                                                      ),
                                                                    "trigger-change": true,
                                                                    multi: true,
                                                                  },
                                                                  on: {
                                                                    change: (
                                                                      v
                                                                    ) =>
                                                                      _vm.handleChangeDepartCommon(
                                                                        v,
                                                                        id,
                                                                        row,
                                                                        col
                                                                      ),
                                                                  },
                                                                },
                                                                "j-select-depart",
                                                                _vm.buildProps(
                                                                  row,
                                                                  col
                                                                ),
                                                                false
                                                              )
                                                            )
                                                          : _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "j-td-span no-edit",
                                                                class: {
                                                                  disabled:
                                                                    _vm.buildProps(
                                                                      row,
                                                                      col
                                                                    ).disabled,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleEditRow(
                                                                        row,
                                                                        col
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .departCompValues[
                                                                      id
                                                                    ]
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : col.type ===
                                                  _vm.formTypes.sel_user
                                                ? [
                                                    _c(
                                                      "a-tooltip",
                                                      _vm._b(
                                                        {},
                                                        "a-tooltip",
                                                        _vm.buildTooltipProps(
                                                          row,
                                                          col,
                                                          id
                                                        ),
                                                        false
                                                      ),
                                                      [
                                                        _vm.isEditRow(row, col)
                                                          ? _c(
                                                              "j-select-user-by-dep",
                                                              _vm._b(
                                                                {
                                                                  key: i,
                                                                  staticStyle: {
                                                                    width:
                                                                      "100%",
                                                                  },
                                                                  attrs: {
                                                                    id: id,
                                                                    value:
                                                                      _vm
                                                                        .userCompValues[
                                                                        id
                                                                      ],
                                                                    placeholder:
                                                                      _vm.replaceProps(
                                                                        col,
                                                                        col.placeholder
                                                                      ),
                                                                    "trigger-change": true,
                                                                    multi: true,
                                                                  },
                                                                  on: {
                                                                    change: (
                                                                      v
                                                                    ) =>
                                                                      _vm.handleChangeUserCommon(
                                                                        v,
                                                                        id,
                                                                        row,
                                                                        col
                                                                      ),
                                                                  },
                                                                },
                                                                "j-select-user-by-dep",
                                                                _vm.buildProps(
                                                                  row,
                                                                  col
                                                                ),
                                                                false
                                                              )
                                                            )
                                                          : _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "j-td-span no-edit",
                                                                class: {
                                                                  disabled:
                                                                    _vm.buildProps(
                                                                      row,
                                                                      col
                                                                    ).disabled,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleEditRow(
                                                                        row,
                                                                        col
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .userCompValues[
                                                                      id
                                                                    ]
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : col.type ===
                                                    _vm.formTypes.date ||
                                                  col.type ===
                                                    _vm.formTypes.datetime
                                                ? [
                                                    _c(
                                                      "a-tooltip",
                                                      _vm._b(
                                                        {},
                                                        "a-tooltip",
                                                        _vm.buildTooltipProps(
                                                          row,
                                                          col,
                                                          id
                                                        ),
                                                        false
                                                      ),
                                                      [
                                                        _vm.isEditRow(row, col)
                                                          ? _c(
                                                              "j-date",
                                                              _vm._b(
                                                                {
                                                                  key: i,
                                                                  staticStyle: {
                                                                    width:
                                                                      "100%",
                                                                  },
                                                                  attrs: {
                                                                    id: id,
                                                                    value:
                                                                      _vm
                                                                        .jdateValues[
                                                                        id
                                                                      ],
                                                                    getCalendarContainer:
                                                                      _vm.getParentContainer,
                                                                    placeholder:
                                                                      _vm.replaceProps(
                                                                        col,
                                                                        col.placeholder
                                                                      ),
                                                                    "trigger-change": true,
                                                                    showTime:
                                                                      col.type ===
                                                                      _vm
                                                                        .formTypes
                                                                        .datetime,
                                                                    dateFormat:
                                                                      col.type ===
                                                                      _vm
                                                                        .formTypes
                                                                        .date
                                                                        ? "YYYY-MM-DD"
                                                                        : "YYYY-MM-DD HH:mm:ss",
                                                                    allowClear:
                                                                      "",
                                                                  },
                                                                  on: {
                                                                    change: (
                                                                      v
                                                                    ) =>
                                                                      _vm.handleChangeJDateCommon(
                                                                        v,
                                                                        id,
                                                                        row,
                                                                        col,
                                                                        col.type ===
                                                                          _vm
                                                                            .formTypes
                                                                            .datetime
                                                                      ),
                                                                  },
                                                                },
                                                                "j-date",
                                                                _vm.buildProps(
                                                                  row,
                                                                  col
                                                                ),
                                                                false
                                                              )
                                                            )
                                                          : _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "j-td-span no-edit",
                                                                class: {
                                                                  disabled:
                                                                    _vm.buildProps(
                                                                      row,
                                                                      col
                                                                    ).disabled,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleEditRow(
                                                                        row,
                                                                        col
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .jdateValues[
                                                                      id
                                                                    ]
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : col.type ===
                                                  _vm.formTypes.input_pop
                                                ? [
                                                    _c(
                                                      "a-tooltip",
                                                      _vm._b(
                                                        {},
                                                        "a-tooltip",
                                                        _vm.buildTooltipProps(
                                                          row,
                                                          col,
                                                          id
                                                        ),
                                                        false
                                                      ),
                                                      [
                                                        _vm.isEditRow(row, col)
                                                          ? _c(
                                                              "j-input-pop",
                                                              _vm._b(
                                                                {
                                                                  key: i,
                                                                  staticStyle: {
                                                                    width:
                                                                      "100%",
                                                                  },
                                                                  attrs: {
                                                                    id: id,
                                                                    width: 300,
                                                                    height: 210,
                                                                    "pop-container": `${_vm.caseId}tbody`,
                                                                    value:
                                                                      _vm
                                                                        .jInputPopValues[
                                                                        id
                                                                      ],
                                                                    getCalendarContainer:
                                                                      _vm.getParentContainer,
                                                                    placeholder:
                                                                      _vm.replaceProps(
                                                                        col,
                                                                        col.placeholder
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    change: (
                                                                      v
                                                                    ) =>
                                                                      _vm.handleChangeJInputPopCommon(
                                                                        v,
                                                                        id,
                                                                        row,
                                                                        col
                                                                      ),
                                                                  },
                                                                },
                                                                "j-input-pop",
                                                                _vm.buildProps(
                                                                  row,
                                                                  col
                                                                ),
                                                                false
                                                              )
                                                            )
                                                          : _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "j-td-span no-edit",
                                                                class: {
                                                                  disabled:
                                                                    _vm.buildProps(
                                                                      row,
                                                                      col
                                                                    ).disabled,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleEditRow(
                                                                        row,
                                                                        col
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .jInputPopValues[
                                                                      id
                                                                    ]
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : col.type ===
                                                  _vm.formTypes.upload
                                                ? _c(
                                                    "div",
                                                    { key: i },
                                                    [
                                                      _vm._l(
                                                        [
                                                          _vm.uploadValues[
                                                            id
                                                          ] || {},
                                                        ],
                                                        function (
                                                          file,
                                                          fileKey
                                                        ) {
                                                          return _vm
                                                            .uploadValues[id] !=
                                                            null
                                                            ? [
                                                                _c(
                                                                  "a-input",
                                                                  {
                                                                    key: fileKey,
                                                                    attrs: {
                                                                      readOnly: true,
                                                                      value:
                                                                        file.name,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "template",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "30px",
                                                                          },
                                                                        slot: "addonBefore",
                                                                      },
                                                                      [
                                                                        file.status ===
                                                                        "uploading"
                                                                          ? _c(
                                                                              "a-tooltip",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    title: `上传中(${Math.floor(
                                                                                      file.percent
                                                                                    )}%)`,
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "a-icon",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        type: "loading",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            )
                                                                          : file.status ===
                                                                            "done"
                                                                          ? _c(
                                                                              "a-tooltip",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    title:
                                                                                      "上传完成",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "a-icon",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        color:
                                                                                          "#00DB00",
                                                                                      },
                                                                                    attrs:
                                                                                      {
                                                                                        type: "check-circle",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _c(
                                                                              "a-tooltip",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    title:
                                                                                      "上传失败",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "a-icon",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        color:
                                                                                          "red",
                                                                                      },
                                                                                    attrs:
                                                                                      {
                                                                                        type: "exclamation-circle",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    col.allowDownload !==
                                                                      false ||
                                                                    col.allowRemove !==
                                                                      false
                                                                      ? _c(
                                                                          "template",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                width:
                                                                                  "30px",
                                                                              },
                                                                            slot: "addonAfter",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "a-dropdown",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    trigger:
                                                                                      [
                                                                                        "click",
                                                                                      ],
                                                                                    placement:
                                                                                      "bottomRight",
                                                                                    getPopupContainer:
                                                                                      _vm.getParentContainer,
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "a-tooltip",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        title:
                                                                                          "操作",
                                                                                        getPopupContainer:
                                                                                          _vm.getParentContainer,
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    file.status !==
                                                                                    "uploading"
                                                                                      ? _c(
                                                                                          "a-icon",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                cursor:
                                                                                                  "pointer",
                                                                                              },
                                                                                            attrs:
                                                                                              {
                                                                                                type: "setting",
                                                                                              },
                                                                                          }
                                                                                        )
                                                                                      : _vm._e(),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                                _c(
                                                                                  "a-menu",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        slot: "overlay",
                                                                                      },
                                                                                    slot: "overlay",
                                                                                  },
                                                                                  [
                                                                                    col.allowDownload !==
                                                                                    false
                                                                                      ? _c(
                                                                                          "a-menu-item",
                                                                                          {
                                                                                            on: {
                                                                                              click:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  return _vm.handleClickDownloadFile(
                                                                                                    id
                                                                                                  )
                                                                                                },
                                                                                            },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "span",
                                                                                              [
                                                                                                _c(
                                                                                                  "a-icon",
                                                                                                  {
                                                                                                    attrs:
                                                                                                      {
                                                                                                        type: "download",
                                                                                                      },
                                                                                                  }
                                                                                                ),
                                                                                                _vm._v(
                                                                                                  " 下载"
                                                                                                ),
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    col.allowRemove !==
                                                                                    false
                                                                                      ? _c(
                                                                                          "a-menu-item",
                                                                                          {
                                                                                            on: {
                                                                                              click:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  return _vm.handleClickDelFile(
                                                                                                    id
                                                                                                  )
                                                                                                },
                                                                                            },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "span",
                                                                                              [
                                                                                                _c(
                                                                                                  "a-icon",
                                                                                                  {
                                                                                                    attrs:
                                                                                                      {
                                                                                                        type: "delete",
                                                                                                      },
                                                                                                  }
                                                                                                ),
                                                                                                _vm._v(
                                                                                                  " 删除"
                                                                                                ),
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e(),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  2
                                                                ),
                                                              ]
                                                            : _vm._e()
                                                        }
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          attrs: {
                                                            hidden:
                                                              _vm.uploadValues[
                                                                id
                                                              ] != null,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "a-tooltip",
                                                            _vm._b(
                                                              {},
                                                              "a-tooltip",
                                                              _vm.buildTooltipProps(
                                                                row,
                                                                col,
                                                                id
                                                              ),
                                                              false
                                                            ),
                                                            [
                                                              _c(
                                                                "a-upload",
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      name: "file",
                                                                      data: {
                                                                        isup: 1,
                                                                      },
                                                                      multiple: false,
                                                                      action:
                                                                        col.action,
                                                                      headers:
                                                                        _vm.uploadGetHeaders(
                                                                          row,
                                                                          col
                                                                        ),
                                                                      showUploadList: false,
                                                                    },
                                                                    on: {
                                                                      change: (
                                                                        v
                                                                      ) =>
                                                                        _vm.handleChangeUpload(
                                                                          v,
                                                                          id,
                                                                          row,
                                                                          col
                                                                        ),
                                                                    },
                                                                  },
                                                                  "a-upload",
                                                                  _vm.buildProps(
                                                                    row,
                                                                    col
                                                                  ),
                                                                  false
                                                                ),
                                                                [
                                                                  _c(
                                                                    "a-button",
                                                                    {
                                                                      attrs: {
                                                                        icon: "upload",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          col.placeholder
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    2
                                                  )
                                                : col.type ===
                                                  _vm.formTypes.popup
                                                ? [
                                                    _c(
                                                      "a-tooltip",
                                                      _vm._b(
                                                        {},
                                                        "a-tooltip",
                                                        _vm.buildTooltipProps(
                                                          row,
                                                          col,
                                                          id
                                                        ),
                                                        false
                                                      ),
                                                      [
                                                        _vm.isEditRow(row, col)
                                                          ? _c(
                                                              "j-popup",
                                                              _vm._b(
                                                                {
                                                                  key: i,
                                                                  staticStyle: {
                                                                    width:
                                                                      "100%",
                                                                  },
                                                                  attrs: {
                                                                    id: id,
                                                                    placeholder:
                                                                      _vm.replaceProps(
                                                                        col,
                                                                        col.placeholder
                                                                      ),
                                                                    value:
                                                                      _vm.getPopupValue(
                                                                        id
                                                                      ),
                                                                    field:
                                                                      col.field ||
                                                                      col.key,
                                                                    "org-fields":
                                                                      col.orgFields,
                                                                    "dest-fields":
                                                                      col.destFields,
                                                                    code: col.popupCode,
                                                                    groupId:
                                                                      _vm.caseId,
                                                                  },
                                                                  on: {
                                                                    input: (
                                                                      value,
                                                                      others
                                                                    ) =>
                                                                      _vm.popupCallback(
                                                                        value,
                                                                        others,
                                                                        id,
                                                                        row,
                                                                        col,
                                                                        rowIndex
                                                                      ),
                                                                  },
                                                                },
                                                                "j-popup",
                                                                _vm.buildProps(
                                                                  row,
                                                                  col
                                                                ),
                                                                false
                                                              )
                                                            )
                                                          : _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "j-td-span no-edit",
                                                                class: {
                                                                  disabled:
                                                                    _vm.buildProps(
                                                                      row,
                                                                      col
                                                                    ).disabled,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleEditRow(
                                                                        row,
                                                                        col
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getPopupValue(
                                                                      id
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : col.type ===
                                                  _vm.formTypes.file
                                                ? _c(
                                                    "div",
                                                    { key: i },
                                                    [
                                                      _vm._l(
                                                        [
                                                          _vm.uploadValues[
                                                            id
                                                          ] || {},
                                                        ],
                                                        function (
                                                          file,
                                                          fileKey
                                                        ) {
                                                          return _vm
                                                            .uploadValues[id] !=
                                                            null
                                                            ? [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    key: fileKey,
                                                                    staticStyle:
                                                                      {
                                                                        position:
                                                                          "relative",
                                                                      },
                                                                  },
                                                                  [
                                                                    file.status ===
                                                                    "uploading"
                                                                      ? _c(
                                                                          "a-tooltip",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                title: `上传中(${Math.floor(
                                                                                  file.percent
                                                                                )}%)`,
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "a-icon",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    color:
                                                                                      "red",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    type: "loading",
                                                                                  },
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    color:
                                                                                      "red",
                                                                                    "margin-left":
                                                                                      "5px",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    file.status
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : file.status ===
                                                                        "done"
                                                                      ? _c(
                                                                          "a-tooltip",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                title:
                                                                                  file.name,
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "a-icon",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    type: "paper-clip",
                                                                                  },
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    "margin-left":
                                                                                      "5px",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.getEllipsisWord(
                                                                                      file.name,
                                                                                      5
                                                                                    )
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _c(
                                                                          "a-tooltip",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                title:
                                                                                  file.name,
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "a-icon",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    color:
                                                                                      "red",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    type: "paper-clip",
                                                                                  },
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    color:
                                                                                      "red",
                                                                                    "margin-left":
                                                                                      "5px",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.getEllipsisWord(
                                                                                      file.name,
                                                                                      5
                                                                                    )
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                    [
                                                                      _c(
                                                                        "a-dropdown",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              "margin-left":
                                                                                "10px",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              trigger:
                                                                                [
                                                                                  "click",
                                                                                ],
                                                                              placement:
                                                                                "bottomRight",
                                                                              getPopupContainer:
                                                                                _vm.getParentContainer,
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "a-tooltip",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  title:
                                                                                    "操作",
                                                                                  getPopupContainer:
                                                                                    _vm.getParentContainer,
                                                                                },
                                                                            },
                                                                            [
                                                                              file.status !==
                                                                              "uploading"
                                                                                ? _c(
                                                                                    "a-icon",
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          cursor:
                                                                                            "pointer",
                                                                                        },
                                                                                      attrs:
                                                                                        {
                                                                                          type: "setting",
                                                                                        },
                                                                                    }
                                                                                  )
                                                                                : _vm._e(),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "a-menu",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  slot: "overlay",
                                                                                },
                                                                              slot: "overlay",
                                                                            },
                                                                            [
                                                                              col.allowDownload !==
                                                                              false
                                                                                ? _c(
                                                                                    "a-menu-item",
                                                                                    {
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.handleClickDownFileByUrl(
                                                                                              id
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        [
                                                                                          _c(
                                                                                            "a-icon",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  type: "download",
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " 下载"
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              _c(
                                                                                "a-menu-item",
                                                                                {
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.handleClickDelFile(
                                                                                          id
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    [
                                                                                      _c(
                                                                                        "a-icon",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              type: "delete",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " 删除"
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "a-menu-item",
                                                                                {
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.handleMoreOperation(
                                                                                          id,
                                                                                          col,
                                                                                          col
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    [
                                                                                      _c(
                                                                                        "a-icon",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              type: "bars",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " 更多"
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                  ],
                                                                  2
                                                                ),
                                                              ]
                                                            : _vm._e()
                                                        }
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          attrs: {
                                                            hidden:
                                                              _vm.uploadValues[
                                                                id
                                                              ] != null,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "a-tooltip",
                                                            _vm._b(
                                                              {},
                                                              "a-tooltip",
                                                              _vm.buildTooltipProps(
                                                                row,
                                                                col,
                                                                id
                                                              ),
                                                              false
                                                            ),
                                                            [
                                                              _c(
                                                                "a-upload",
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      name: "file",
                                                                      data: {
                                                                        isup: 1,
                                                                      },
                                                                      multiple: false,
                                                                      action:
                                                                        _vm.getUploadAction(
                                                                          col.action
                                                                        ),
                                                                      headers:
                                                                        _vm.uploadGetHeaders(
                                                                          row,
                                                                          col
                                                                        ),
                                                                      showUploadList: false,
                                                                    },
                                                                    on: {
                                                                      change: (
                                                                        v
                                                                      ) =>
                                                                        _vm.handleChangeUpload(
                                                                          v,
                                                                          id,
                                                                          row,
                                                                          col
                                                                        ),
                                                                    },
                                                                  },
                                                                  "a-upload",
                                                                  _vm.buildProps(
                                                                    row,
                                                                    col
                                                                  ),
                                                                  false
                                                                ),
                                                                [
                                                                  _c(
                                                                    "a-button",
                                                                    {
                                                                      attrs: {
                                                                        icon: "upload",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "上传文件"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    2
                                                  )
                                                : col.type ===
                                                  _vm.formTypes.image
                                                ? _c(
                                                    "div",
                                                    { key: i },
                                                    [
                                                      _vm._l(
                                                        [
                                                          _vm.uploadValues[
                                                            id
                                                          ] || {},
                                                        ],
                                                        function (
                                                          file,
                                                          fileKey
                                                        ) {
                                                          return _vm
                                                            .uploadValues[id] !=
                                                            null
                                                            ? [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    key: fileKey,
                                                                    staticStyle:
                                                                      {
                                                                        position:
                                                                          "relative",
                                                                      },
                                                                  },
                                                                  [
                                                                    !_vm
                                                                      .uploadValues[
                                                                      id
                                                                    ] ||
                                                                    !(
                                                                      _vm
                                                                        .uploadValues[
                                                                        id
                                                                      ][
                                                                        "url"
                                                                      ] ||
                                                                      _vm
                                                                        .uploadValues[
                                                                        id
                                                                      ][
                                                                        "path"
                                                                      ] ||
                                                                      _vm
                                                                        .uploadValues[
                                                                        id
                                                                      ][
                                                                        "message"
                                                                      ]
                                                                    )
                                                                      ? [
                                                                          _c(
                                                                            "a-icon",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  type: "loading",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ]
                                                                      : _vm
                                                                          .uploadValues[
                                                                          id
                                                                        ][
                                                                          "path"
                                                                        ]
                                                                      ? [
                                                                          _c(
                                                                            "img",
                                                                            {
                                                                              staticClass:
                                                                                "j-editable-image",
                                                                              attrs:
                                                                                {
                                                                                  src: _vm.getCellImageView(
                                                                                    id
                                                                                  ),
                                                                                  alt: "无图片",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.handleMoreOperation(
                                                                                      id,
                                                                                      "img",
                                                                                      col
                                                                                    )
                                                                                  },
                                                                              },
                                                                            }
                                                                          ),
                                                                        ]
                                                                      : [
                                                                          _c(
                                                                            "a-icon",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  color:
                                                                                    "red",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  type: "exclamation-circle",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.handleClickShowImageError(
                                                                                      id
                                                                                    )
                                                                                  },
                                                                              },
                                                                            }
                                                                          ),
                                                                        ],
                                                                    _c(
                                                                      "template",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "30px",
                                                                          },
                                                                        slot: "addonBefore",
                                                                      },
                                                                      [
                                                                        file.status ===
                                                                        "uploading"
                                                                          ? _c(
                                                                              "a-tooltip",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    title: `上传中(${Math.floor(
                                                                                      file.percent
                                                                                    )}%)`,
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "a-icon",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        type: "loading",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            )
                                                                          : file.status ===
                                                                            "done"
                                                                          ? _c(
                                                                              "a-tooltip",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    title:
                                                                                      "上传完成",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "a-icon",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        color:
                                                                                          "#00DB00",
                                                                                      },
                                                                                    attrs:
                                                                                      {
                                                                                        type: "check-circle",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _c(
                                                                              "a-tooltip",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    title:
                                                                                      "上传失败",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "a-icon",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        color:
                                                                                          "red",
                                                                                      },
                                                                                    attrs:
                                                                                      {
                                                                                        type: "exclamation-circle",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "a-dropdown",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              "margin-left":
                                                                                "10px",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              trigger:
                                                                                [
                                                                                  "click",
                                                                                ],
                                                                              placement:
                                                                                "bottomRight",
                                                                              getPopupContainer:
                                                                                _vm.getParentContainer,
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "a-tooltip",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  title:
                                                                                    "操作",
                                                                                  getPopupContainer:
                                                                                    _vm.getParentContainer,
                                                                                },
                                                                            },
                                                                            [
                                                                              file.status !==
                                                                              "uploading"
                                                                                ? _c(
                                                                                    "a-icon",
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          cursor:
                                                                                            "pointer",
                                                                                        },
                                                                                      attrs:
                                                                                        {
                                                                                          type: "setting",
                                                                                        },
                                                                                    }
                                                                                  )
                                                                                : _vm._e(),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "a-menu",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  slot: "overlay",
                                                                                },
                                                                              slot: "overlay",
                                                                            },
                                                                            [
                                                                              col.allowDownload !==
                                                                              false
                                                                                ? _c(
                                                                                    "a-menu-item",
                                                                                    {
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.handleClickDownFileByUrl(
                                                                                              id
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        [
                                                                                          _c(
                                                                                            "a-icon",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  type: "download",
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " 下载"
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              _c(
                                                                                "a-menu-item",
                                                                                {
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.handleClickDelFile(
                                                                                          id
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    [
                                                                                      _c(
                                                                                        "a-icon",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              type: "delete",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " 删除"
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "a-menu-item",
                                                                                {
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.handleMoreOperation(
                                                                                          id,
                                                                                          "img",
                                                                                          col
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    [
                                                                                      _c(
                                                                                        "a-icon",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              type: "bars",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " 更多"
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                  ],
                                                                  2
                                                                ),
                                                              ]
                                                            : _vm._e()
                                                        }
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          attrs: {
                                                            hidden:
                                                              _vm.uploadValues[
                                                                id
                                                              ] != null,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "a-tooltip",
                                                            _vm._b(
                                                              {},
                                                              "a-tooltip",
                                                              _vm.buildTooltipProps(
                                                                row,
                                                                col,
                                                                id
                                                              ),
                                                              false
                                                            ),
                                                            [
                                                              _c(
                                                                "a-upload",
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      name: "file",
                                                                      data: {
                                                                        isup: 1,
                                                                      },
                                                                      multiple: false,
                                                                      action:
                                                                        _vm.getUploadAction(
                                                                          col.action
                                                                        ),
                                                                      headers:
                                                                        _vm.uploadGetHeaders(
                                                                          row,
                                                                          col
                                                                        ),
                                                                      showUploadList: false,
                                                                    },
                                                                    on: {
                                                                      change: (
                                                                        v
                                                                      ) =>
                                                                        _vm.handleChangeUpload(
                                                                          v,
                                                                          id,
                                                                          row,
                                                                          col
                                                                        ),
                                                                    },
                                                                  },
                                                                  "a-upload",
                                                                  _vm.buildProps(
                                                                    row,
                                                                    col
                                                                  ),
                                                                  false
                                                                ),
                                                                [
                                                                  _c(
                                                                    "a-button",
                                                                    {
                                                                      attrs: {
                                                                        icon: "upload",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "上传图片"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    2
                                                  )
                                                : col.type ===
                                                  _vm.formTypes.radio
                                                ? [
                                                    _c(
                                                      "a-tooltip",
                                                      _vm._b(
                                                        {},
                                                        "a-tooltip",
                                                        _vm.buildTooltipProps(
                                                          row,
                                                          col,
                                                          id
                                                        ),
                                                        false
                                                      ),
                                                      [
                                                        _c(
                                                          "a-radio-group",
                                                          _vm._b(
                                                            {
                                                              key: i,
                                                              attrs: {
                                                                id: id,
                                                                value:
                                                                  _vm
                                                                    .radioValues[
                                                                    id
                                                                  ],
                                                              },
                                                              on: {
                                                                change: (e) =>
                                                                  _vm.handleRadioChange(
                                                                    e.target
                                                                      .value,
                                                                    id,
                                                                    row,
                                                                    col
                                                                  ),
                                                              },
                                                            },
                                                            "a-radio-group",
                                                            _vm.buildProps(
                                                              row,
                                                              col
                                                            ),
                                                            false
                                                          ),
                                                          _vm._l(
                                                            col.options,
                                                            function (
                                                              item,
                                                              key
                                                            ) {
                                                              return _c(
                                                                "a-radio",
                                                                {
                                                                  key: key,
                                                                  attrs: {
                                                                    value:
                                                                      item.value,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.text
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : col.type ===
                                                  _vm.formTypes.list_multi
                                                ? [
                                                    _c(
                                                      "a-tooltip",
                                                      _vm._b(
                                                        {},
                                                        "a-tooltip",
                                                        _vm.buildTooltipProps(
                                                          row,
                                                          col,
                                                          id
                                                        ),
                                                        false
                                                      ),
                                                      [
                                                        _vm.isEditRow(row, col)
                                                          ? _c(
                                                              "a-select",
                                                              _vm._b(
                                                                {
                                                                  key: i,
                                                                  staticStyle: {
                                                                    width:
                                                                      "100%",
                                                                  },
                                                                  attrs: {
                                                                    id: id,
                                                                    mode: "multiple",
                                                                    maxTagCount: 1,
                                                                    value:
                                                                      _vm
                                                                        .multiSelectValues[
                                                                        id
                                                                      ],
                                                                    options:
                                                                      col.options,
                                                                    getPopupContainer:
                                                                      _vm.getParentContainer,
                                                                    placeholder:
                                                                      _vm.replaceProps(
                                                                        col,
                                                                        col.placeholder
                                                                      ),
                                                                    allowClear:
                                                                      "",
                                                                  },
                                                                  on: {
                                                                    change: (
                                                                      v
                                                                    ) =>
                                                                      _vm.handleMultiSelectChange(
                                                                        v,
                                                                        id,
                                                                        row,
                                                                        col
                                                                      ),
                                                                  },
                                                                },
                                                                "a-select",
                                                                _vm.buildProps(
                                                                  row,
                                                                  col
                                                                ),
                                                                false
                                                              )
                                                            )
                                                          : _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "j-td-span no-edit",
                                                                class: {
                                                                  disabled:
                                                                    _vm.buildProps(
                                                                      row,
                                                                      col
                                                                    ).disabled,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleEditRow(
                                                                        row,
                                                                        col
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getSelectTranslateText(
                                                                      _vm
                                                                        .multiSelectValues[
                                                                        id
                                                                      ],
                                                                      row,
                                                                      col
                                                                    )
                                                                  ) + " "
                                                                ),
                                                              ]
                                                            ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : col.type ===
                                                  _vm.formTypes.sel_search
                                                ? [
                                                    _c(
                                                      "a-tooltip",
                                                      _vm._b(
                                                        {},
                                                        "a-tooltip",
                                                        _vm.buildTooltipProps(
                                                          row,
                                                          col,
                                                          id
                                                        ),
                                                        false
                                                      ),
                                                      [
                                                        _vm.isEditRow(row, col)
                                                          ? _c(
                                                              "a-select",
                                                              _vm._b(
                                                                {
                                                                  key: i,
                                                                  staticStyle: {
                                                                    width:
                                                                      "100%",
                                                                  },
                                                                  attrs: {
                                                                    id: id,
                                                                    showSearch:
                                                                      "",
                                                                    optionFilterProp:
                                                                      "children",
                                                                    filterOption:
                                                                      _vm.filterOption,
                                                                    value:
                                                                      _vm
                                                                        .searchSelectValues[
                                                                        id
                                                                      ],
                                                                    options:
                                                                      col.options,
                                                                    getPopupContainer:
                                                                      _vm.getParentContainer,
                                                                    placeholder:
                                                                      _vm.replaceProps(
                                                                        col,
                                                                        col.placeholder
                                                                      ),
                                                                    allowClear:
                                                                      "",
                                                                  },
                                                                  on: {
                                                                    change: (
                                                                      v
                                                                    ) =>
                                                                      _vm.handleSearchSelectChange(
                                                                        v,
                                                                        id,
                                                                        row,
                                                                        col
                                                                      ),
                                                                  },
                                                                },
                                                                "a-select",
                                                                _vm.buildProps(
                                                                  row,
                                                                  col
                                                                ),
                                                                false
                                                              )
                                                            )
                                                          : _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "j-td-span no-edit",
                                                                class: {
                                                                  disabled:
                                                                    _vm.buildProps(
                                                                      row,
                                                                      col
                                                                    ).disabled,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleEditRow(
                                                                        row,
                                                                        col
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getSelectTranslateText(
                                                                      _vm
                                                                        .searchSelectValues[
                                                                        id
                                                                      ],
                                                                      row,
                                                                      col
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : col.type ===
                                                  _vm.formTypes.slot
                                                ? _c(
                                                    "div",
                                                    { key: i },
                                                    [
                                                      _c(
                                                        "a-tooltip",
                                                        _vm._b(
                                                          {},
                                                          "a-tooltip",
                                                          _vm.buildTooltipProps(
                                                            row,
                                                            col,
                                                            id
                                                          ),
                                                          false
                                                        ),
                                                        [
                                                          _vm._t(
                                                            col.slot ||
                                                              col.slotName ||
                                                              col.key,
                                                            null,
                                                            {
                                                              index: rowIndex,
                                                              text: _vm
                                                                .slotValues[id],
                                                              value:
                                                                _vm.slotValues[
                                                                  id
                                                                ],
                                                              column: col,
                                                              rowId:
                                                                _vm.getCleanId(
                                                                  row.id
                                                                ),
                                                              getValue: () =>
                                                                _vm._getValueForSlot(
                                                                  row.id
                                                                ),
                                                              caseId:
                                                                _vm.caseId,
                                                              allValues:
                                                                _vm._getAllValuesForSlot(),
                                                              target:
                                                                _vm.getVM(),
                                                              handleChange: (
                                                                v
                                                              ) =>
                                                                _vm.handleChangeSlotCommon(
                                                                  v,
                                                                  id,
                                                                  row,
                                                                  col
                                                                ),
                                                              isNotPass:
                                                                _vm.notPassedIds.includes(
                                                                  col.key +
                                                                    row.id
                                                                ),
                                                            }
                                                          ),
                                                        ],
                                                        2
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "span",
                                                    _vm._b(
                                                      { key: i },
                                                      "span",
                                                      _vm.buildProps(row, col),
                                                      false
                                                    ),
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.inputValues[
                                                            rowIndex
                                                          ][col.key]
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                            ]
                                          }
                                        ),
                                      ],
                                      2
                                    )
                                  }),
                                ],
                                2
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                  _vm.showStatisticsRow
                    ? _c(
                        "div",
                        {
                          staticClass: "tr",
                          style: {
                            ..._vm.buildTrStyle(_vm.rows.length),
                            height: "32px",
                          },
                        },
                        [
                          _vm.dragSort
                            ? _c("div", {
                                staticClass: "td td-ds",
                                style: _vm.style.tdLeft,
                              })
                            : _vm._e(),
                          _vm.rowSelection
                            ? _c(
                                "div",
                                {
                                  staticClass: "td td-cb",
                                  style: _vm.style.tdLeft,
                                },
                                [_vm._v("\n            统计\n          ")]
                              )
                            : _vm._e(),
                          _vm.rowNumber
                            ? _c(
                                "div",
                                {
                                  staticClass: "td td-num",
                                  style: _vm.style.tdLeft,
                                },
                                [
                                  !_vm.rowSelection
                                    ? _c("span", [_vm._v("统计")])
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          _vm._l(_vm.columns, function (col) {
                            return [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: col.type !== _vm.formTypes.hidden,
                                      expression:
                                        "col.type !== formTypes.hidden",
                                    },
                                  ],
                                  key: col.key,
                                  staticClass: "td",
                                  style: _vm.buildTdStyle(col),
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            col.type ===
                                            _vm.formTypes.inputNumber,
                                          expression:
                                            "col.type === formTypes.inputNumber",
                                        },
                                      ],
                                      staticStyle: { padding: "0 5px" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.statisticsColumns[col.key])
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _c("j-file-pop", {
            ref: "filePop",
            attrs: { number: _vm.number },
            on: { ok: _vm.handleFileSuccess },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }